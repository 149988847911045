/** to use the mixin the following "refs" have to exist in the component:
 *  1) chatContainer
 *  2) chatMessages
 *  Examples are in ChatContainer.vue and JobInterestForm.vue
 */
import { _isInputBugged } from "@/helpers/isInputBugged"

export default {
    props: {
        /** if the chat is floating - with a fixed height (floatingHeight prop) displayed in a dialog or a popup */
        floating: {
            type: Boolean,
            default: false,
        },
        /** fixed height of the chat container in floating mode */
        floatingHeight: {
            type: [Number, String],
            default: 400,
        },
        /** blocking all the scrolling on the page, but not when a Modal is active, or the chat is floating */
        blockScrolling: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            _touchStartY: 0,
            _eventListenerOptions: { capture: true, passive: false },
        }
    },
    mounted() {
        if (this.floating) {
            // floating chat (not a fullscreen version) doesn't have the problem with safari
            this._setChatContainerHeight(this.floatingHeight)
        } else {
            // otherwise, initialize the event listeners
            if (!_isInputBugged()) return
            // init page dimensions on page mount
            this._initPageSize()
        }
    },
    methods: {
        _setChatContainerHeight(height) {
            if (!this.$refs.chatContainer) return
            if (typeof height === "number")
                this.$refs.chatContainer.style.height = height + "px"
            else this.$refs.chatContainer.style.height = height
        },
        _initPageSize() {
            this.$nextTick(() => {
                const activeDialogElement = this._getDOMElement(
                    "v-dialog__content--active"
                )
                const applicationWrapElement = this._getDOMElement(
                    "v-application--wrap"
                )
                const chatContainerElement = this.$refs.chatContainer
                this._setStyle(chatContainerElement, "height", "100dvh")
                this._setStyle(activeDialogElement, "minHeight", "100dvh")
                this._setStyle(applicationWrapElement, "minHeight", "100dvh")
                setTimeout(() => {
                    const vMainElement = this._getDOMElement("v-main")
                    this._setStyle(vMainElement, "minHeight", "100dvh")
                }, 200)
            })
        },
        _getDOMElement(name) {
            const els = document.getElementsByClassName(name)
            if (els.length === 0) return
            return els[0]
        },
        _setStyle(el, field, value) {
            if (!el) return
            el.style[field] = value
        },
    },
}
