/**
 * Detects the virual keyboard of iOS.
 * Apple decided to fool the whole world by not changing the window size
 * when opening the keyboard.
 *
 * Therefore, one needs to detect if there is an virutal keyboard on safari
 * from iOS with that effect.
 *
 * This is part of the fix for the chat.
 * @returns Boolean: if there is a virtual keyboard in safari with touch
 */
export function _isInputBugged() {
    const isTouchSupported =
        "ontouchstart" in window ||
        // eslint-disable-next-line no-undef
        (window.DocumentTouch && document instanceof DocumentTouch)
    const isMobile =
        navigator.maxTouchPoints > 0 &&
        navigator.userAgent.search(
            /iOS|iPhone OS|Android|BlackBerry|BB10|Series ?[64]0|J2ME|MIDP|opera mini|opera mobi|mobi.+Gecko|Windows Phone/i
        ) !== -1
    const isIpad =
        navigator.userAgent.match(/Mac/) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2
    return (isMobile && isTouchSupported) || isIpad
}
export function _isInputBlockedInSafari() {
    const isTouchSupported =
        "ontouchstart" in window ||
        // eslint-disable-next-line no-undef
        (window.DocumentTouch && document instanceof DocumentTouch)
    const ua = navigator.userAgent
    const isIphone = isTouchSupported && ua.includes("iPhone OS")

    // check for the buggy version
    return isIphone && ua.includes("15_0_1")
}
