
import tapoutside from "@/directives/tapoutside"
import UserAvatar from "@/components/user/UserAvatar"
import { routeToBusiness } from "@/helpers/routing"
export default {
    name: "GroupChatMembersMenu",
    directives: {
        tapoutside,
    },
    components: { UserAvatar },
    props: {
        /** Menu state */
        menu: {
            type: Boolean,
            default: false,
        },
        participants: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    computed: {
        model: {
            get() {
                return this.menu
            },
            set(value) {
                this.$emit("update:menu", value)
            },
        },
    },
    methods: {
        isLink(participant) {
            if (!this.$can.admin()) {
                const business = participant?.business
                if (
                    business &&
                    !business.permissions?.includes("publish_business")
                ) {
                    return false
                }
            }

            return true
        },
        linkToBusiness(participant) {
            if (!this.isLink(participant) || !participant.business) return

            this.$router.push(routeToBusiness(participant.business))
        },
    },
}
