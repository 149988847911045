
import Button from "@/components/base/Button"
import UserAvatar from "@/components/user/UserAvatar"
import GroupChatMembersMenu from "@/components/chat/GroupChatMembersMenu"
import { getParticipantsByConversationId } from "@/services/v2/chat.service"
import { mdiChevronLeft, mdiDotsHorizontal } from "@mdi/js"
const NUM_PREVIEW_PARTITICIPANTS = 4

export default {
    name: "GroupChatHeader",
    components: { GroupChatMembersMenu, Button, UserAvatar },
    props: {
        conversationId: {
            type: Number,
            default: null,
        },
        /** if "< zurück" button is shown */
        showBackBtn: {
            type: Boolean,
            default: false,
        },
    },
    icons: { mdiChevronLeft, mdiDotsHorizontal },
    data: function () {
        return {
            showMenu: false,
            participants: [],
        }
    },
    computed: {
        previewParticipants() {
            return this.participants?.slice(0, NUM_PREVIEW_PARTITICIPANTS)
        },
        numberOfMoreParticipants() {
            const diff = this.participants?.length - NUM_PREVIEW_PARTITICIPANTS
            return diff > 0 ? diff : null
        },
        hasMoreThanPreviewParticipants() {
            return this.participants?.length > NUM_PREVIEW_PARTITICIPANTS
        },
    },
    async mounted() {
        this.fetchAllParticipants()
    },
    methods: {
        fetchAllParticipants() {
            this.$api
                .run(getParticipantsByConversationId, this.conversationId)
                .then((participants) => (this.participants = participants))
        },
    },
}
