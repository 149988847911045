
import ChatMessagesContainer from "@/components/chat/ChatMessagesContainer"
import ChatInput from "@/components/chat/input/ChatInput"
import ChatProfileCard from "@/components/chat/ChatProfileCard"
import GroupChatHeader from "@/components/chat/GroupChatHeader.vue"
import ProgressCircular from "@/components/base/ProgressCircular"
import Button from "@/components/base/Button"
import { mapGetters } from "vuex"
import { mdiChevronDown } from "@mdi/js"

import chatMixin from "@/mixins/chatMixin"
import {
    routeToBusiness,
    routeToOabatlyticsDetailedUserStatistics,
} from "@/helpers/routing"

export default {
    name: "ChatContainer",
    components: {
        GroupChatHeader,
        ProgressCircular,
        ChatMessagesContainer,
        ChatInput,
        ChatProfileCard,
        Button,
    },
    mixins: [chatMixin],
    emits: ["back-btn-click", "message-sent"],
    props: {
        /** disable the clickable profile card regardless of whether the link prop exists or not */
        noLink: {
            type: Boolean,
            default: false,
        },
        /** if "< zurück" button in the profile card is shown  */
        showBackBtn: {
            type: Boolean,
            default: false,
        },
        /** disable the attachments upload */
        hideAttachments: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mdiChevronDown,
        }
    },
    mounted() {
        document.addEventListener(
            "visibilitychange",
            this.readConversationExecute
        )
    },
    beforeDestroy() {
        document.removeEventListener(
            "visibilitychange",
            this.readConversationExecute
        )
        this.$store.commit("chat/setShowUsers", true)
    },
    computed: {
        ...mapGetters("chat", {
            conversation: "conversation",
            user: "user",
            messages: "messages",
            profile: "profile",
            displayedUser: "displayedUser",
            unreadMessages: "unreadMessages",
            business: "business",
            loadingConversation: "loadingConversation",
            loadingMessages: "loadingMessages",
            showUsers: "showUsers",
            isPaginating: "isPaginating",
        }),
        ...mapGetters("account", ["authDialog"]),
        isUserTyping() {
            return this.$store.getters["chat/isUserTyping"]({
                conversationId: this.conversation?.id,
                userId: this.conversation?.user?.id,
            })
        },
        hasSentMessage() {
            const userId = this.$auth.user?.id
            return this.messages.some((msg) => msg.sender_id === userId)
        },
        isLoading() {
            return this.loadingMessages || this.loadingConversation
        },
        showContainerTopPadding() {
            return (
                (this.$can.school() || this.$can.region()) &&
                this.$route.path.includes("chat")
            )
        },
        link() {
            if (!this.business && this.$can.support()) {
                return routeToOabatlyticsDetailedUserStatistics(
                    this.displayedUser?.id
                )
            } else {
                return routeToBusiness(this.business)
            }
        },
    },
    methods: {
        readConversationExecute() {
            if (this.unreadMessages > 0) {
                this.$store.dispatch("chat/readConversation")
            }
        },
    },
}
